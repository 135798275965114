import {DEFAULT_INTL} from '@/config/constants'
import {useAuth} from '@/hooks/useAuth'
import {useMain} from '@/hooks/useMain'
import useInternationalization from '@/internationalization/useInternationalization'
import {StoreAndTaskReports} from '@/pages/StoreAndTaskReports'
import {IntlProvider, useIntl} from 'react-intl'

export const Reports = () => {
  const {formatMessage} = useIntl()
  const {
    claims: {firstName},
  } = useAuth()

  useMain()

  return (
    <div className="flex flex-col">
      <p className="mb-4 text-3xl font-bold" data-cy="welcome-message">
        {formatMessage(
          {id: 'NBA_LIST.WELCOME_MESSAGE'},
          {
            userName: firstName || 'User',
          }
        )}
      </p>
      <StoreAndTaskReports />
    </div>
  )
}

function FederateReports() {
  const [language, message] = useInternationalization(DEFAULT_INTL)

  return (
    <IntlProvider locale={language} messages={message}>
      <Reports />
    </IntlProvider>
  )
}

export default FederateReports

import {ReportTaskSchema} from '@/types/StoreReport'
import {ProgressBar} from 'pepsico-ds'

interface ValueCellProp {
  value: string
}

interface PregressCellProps {
  value: string
  total: string
}
export const ValueCell = ({value}: ValueCellProp) => {
  return (
    <div
      data-testid="value-cell"
      data-cy="value-cell"
      className="font-inter truncate-ellipsis max-w-[250px] text-sm"
      title={value}
    >
      {value}
    </div>
  )
}
export const PregressCell = ({value, total}: PregressCellProps) => {
  const percentage = (Number(value) / Number(total)) * 100
  return (
    <div
      data-testid="progress-cell"
      data-cy="progress-cell"
      className="font-inter truncate-ellipsis flex max-w-[350px] flex-row items-center justify-between  text-sm"
      title={value}
    >
      <div className="flex-1 pr-2">
        <ProgressBar
          endLabel=""
          label=""
          max={100}
          size="medium"
          startLabel=""
          status="active"
          value={percentage}
          variant="determinate"
        />
      </div>
      <span className="ml text-xs font-normal	">
        {value}/{total}
      </span>
    </div>
  )
}

export const PregressPercentageCell = ({value, total}: PregressCellProps) => {
  const percentage = (Number(value) / Number(total)) * 100
  return (
    <div
      data-testid="progress-cell"
      data-cy="progress-cell"
      className="font-inter truncate-ellipsis flex max-w-[350px] flex-row items-center justify-between  text-sm"
      title={value}
    >
      <div className="flex-1 pr-2">
        <ProgressBar
          endLabel=""
          label=""
          max={100}
          size="medium"
          startLabel=""
          status="active"
          value={percentage}
          variant="determinate"
        />
      </div>
      <span className="ml text-xs font-normal	">{percentage >= 100 ? 100 : percentage}%</span>
    </div>
  )
}

export const NBADescriptionCell = ({description}: {description: string}) => {
  return (
    <div data-testid="description-cell" className="NBA-table-style">
      {description}
    </div>
  )
}

export const StatusAndProgress = ({tasks, threshold}: {tasks: ReportTaskSchema[]; threshold: number}) => {
  const tasksCompleted = threshold - tasks.filter(task => task.status === 'COMPLETED').length
  console.log('tasks', tasks)
  return (
    <p>
      {tasks.length} out of {tasksCompleted}
    </p>
  )
}

import {ColumnDef} from '@tanstack/react-table'
import {CSSProperties} from 'react'
import {IntlFormatters} from 'react-intl'
import {DataTableColumnHeader} from '../../../shared-components/DataTable/DataTableColumnHeader'
import {StoreReportItem} from '@/types/StoreReport'
import {PregressCell, ValueCell} from './CustomCells'
import {NBASReportStatusBadge} from '@/components/NBAStatusBadge/NBAStatusBadge'

export type CustomColumnMeta = {
  styles?: CSSProperties
}

export const getTableColumns = (
  formatMessage: IntlFormatters['formatMessage']
): ColumnDef<StoreReportItem, CustomColumnMeta>[] => {
  return [
    {
      accessorKey: 'storeId',
      header: ({column}) => <DataTableColumnHeader {...column} title={formatMessage({id: 'REPORTS.STORE_ID'})} />,
      cell: ({row}) => <ValueCell value={row.getValue('storeId')} />,
      meta: {
        styles: {
          minWidth: 120,
          maxWidth: 120,
          width: '22%',
        },
      },
    },
    {
      accessorKey: 'nbaName',
      header: ({column}) => (
        <DataTableColumnHeader {...column} title={formatMessage({id: 'NBA_LIST.TABLE_NAME_COLUMN'})} />
      ),
      cell: () => {
        const urlParams = new URLSearchParams(window?.location?.search)
        const nbaName = urlParams.get('nbaName')
        return <ValueCell value={String(nbaName)} />
      },
      meta: {
        styles: {
          minWidth: 150,
          maxWidth: 150,
          width: '22%',
        },
      },
    },
    {
      accessorKey: 'status',
      header: ({column}) => (
        <DataTableColumnHeader {...column} title={formatMessage({id: 'NBA_LIST.TABLE_STATUS_COLUMN'})} />
      ),
      meta: {
        styles: {
          minWidth: 100,
          width: '15%',
        },
      },
      cell: ({row}) => (
        <NBASReportStatusBadge
          threshold={row.original.threshold}
          thresholdCompletion={row.original.thresholdCompletion}
        />
      ),
    },
    {
      accessorKey: 'threshold',
      header: ({column}) => <DataTableColumnHeader {...column} title={formatMessage({id: 'REPORTS.THRESHOLD'})} />,
      meta: {
        styles: {
          minWidth: 100,
          width: '15%',
        },
      },
      cell: ({row}) => <ValueCell value={row.getValue('threshold')} />,
    },
    {
      accessorKey: 'reward',
      header: ({column}) => <DataTableColumnHeader {...column} title={formatMessage({id: 'REPORTS.REWARD'})} />,
      meta: {
        styles: {
          minWidth: 100,
          width: '15%',
        },
      },
      cell: ({row}) => <ValueCell value={row.getValue('reward')} />,
    },
    {
      accessorKey: 'thresholdCompletion',
      header: ({column}) => <DataTableColumnHeader {...column} title={formatMessage({id: 'REPORTS.TASK_PROGRESS'})} />,
      meta: {
        styles: {
          minWidth: 150,
          width: '15%',
        },
      },
      cell: ({row}) => (
        <PregressCell total={String(row.original.threshold)} value={row.getValue('thresholdCompletion')} />
      ),
    },
  ]
}

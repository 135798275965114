import {ComponentPropsWithRef, Ref, forwardRef, useImperativeHandle} from 'react'
import {useIntl} from 'react-intl'
import {DataTable} from '../../shared-components/DataTable'
import {getTableColumns} from './components/columns'
import {Icon} from 'pepsico-ds'
import {useReportList} from '@/components/ReportsListTable/useReportList.hook'

type ReportsListTableProps = ComponentPropsWithRef<'div'> & {
  reporttype: string
}

export type ReportsListTableHandler = {
  reload: () => void
  onSearchQueryChange: (query: string) => void
}

export const ReportsListTable = forwardRef<ReportsListTableHandler, ReportsListTableProps>(
  ({reporttype}: ReportsListTableProps, ref: Ref<ReportsListTableHandler>) => {
    const {formatMessage} = useIntl()

    console.log('reporttype', reporttype)

    const {
      isFetching,
      data,
      error,
      reload,
      requestParams,
      onSearchQueryChange,
      onPaginationDidChange,
      onSortingDidChange,
      sortingColumn,
    } = useReportList()

    useImperativeHandle(
      ref,
      () => ({
        reload,
        onSearchQueryChange,
      }),
      [reload, onSearchQueryChange]
    )

    return (
      <>
        {isFetching && (
          <p className="text-md my-16 items-center text-center" data-testid="nbas-loading">
            {formatMessage({id: 'NBA_LIST.LOADING_TABLE_LABEL'})}
          </p>
        )}
        {!isFetching && error && (
          <p className="text-md my-16 items-center text-center" data-testid="nbas-error">
            {formatMessage({id: 'NBA_LIST.ERROR_TABLE_LABEL'})}
          </p>
        )}
        {!isFetching && !error && data?.content?.length == 0 && (
          <div className="rounded-sm border border-primary-100">
            <div className=" mb-4 mt-8 flex justify-center [&_.icon]:!text-4xl	">
              <Icon icon="mobiledata_off" size="medium" className="$color-icon" />
            </div>
            <p className="text-md mb-4  items-center text-center font-semibold">
              {formatMessage({id: 'NBA_LIST.NODATA_TITLE'})}
            </p>
            <p className="mb-16 items-center text-center text-xs">{formatMessage({id: 'NBA_LIST.NODATA_SUBTITLE'})}</p>
          </div>
        )}
        {!isFetching && !error && (data?.content?.length ?? 0) > 0 && (
          <DataTable
            columns={getTableColumns(formatMessage)}
            data={data?.content}
            pagination={{
              pageIndex: requestParams.pageNumber,
              pageSize: requestParams.pageSize,
            }}
            pageCount={data?.pagination?.totalPages ?? 0}
            onPaginationDidChange={onPaginationDidChange}
            sortingColumn={sortingColumn}
            onSortingDidChange={onSortingDidChange}
            // onSearchQueryChange={onSearchQueryChange} TODO: don not remove
            data-testid="nbas-content"
            data-cy="nbas-content"
          />
        )}
      </>
    )
  }
)

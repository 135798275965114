import {ColumnDef} from '@tanstack/react-table'
import {CSSProperties} from 'react'
import {IntlFormatters} from 'react-intl'
import {DataTableColumnHeader} from '../../../shared-components/DataTable/DataTableColumnHeader'
import {StoreTaskItem} from '@/types/StoreReport'
import {PregressPercentageCell, ValueCell} from './CustomCells'

export type CustomColumnMeta = {
  styles?: CSSProperties
}

export const getTableColumns = (
  formatMessage: IntlFormatters['formatMessage']
): ColumnDef<StoreTaskItem, CustomColumnMeta>[] => {
  return [
    {
      accessorKey: 'storeId',
      header: ({column}) => <DataTableColumnHeader {...column} title={formatMessage({id: 'REPORTS.STORE_ID'})} />,
      cell: ({row}) => <ValueCell value={row.getValue('storeId')} />,
      meta: {
        styles: {
          minWidth: 120,
          maxWidth: 100,
        },
      },
    },
    {
      accessorKey: 'name',
      header: ({column}) => (
        <DataTableColumnHeader {...column} title={formatMessage({id: 'NBA_LIST.TABLE_NAME_COLUMN'})} />
      ),
      cell: () => {
        const urlParams = new URLSearchParams(window?.location?.search)
        const nbaName = urlParams.get('nbaName')
        return <ValueCell value={String(nbaName)} />
      },
    },
    {
      accessorKey: 'taskType',
      header: ({column}) => (
        <DataTableColumnHeader {...column} title={formatMessage({id: 'NBA_LIST.TABLE_TASK_TYPE_COLUMN'})} />
      ),
      meta: {
        styles: {
          minWidth: 120,
          width: '15%',
        },
      },
      cell: ({row}) => <ValueCell value={row.getValue('taskType')} />,
    },
    {
      accessorKey: 'targetValue',
      header: ({column}) => (
        <DataTableColumnHeader {...column} title={formatMessage({id: 'NBA_LIST.TABLE_TASK_BASE_LINE_COLUMN'})} />
      ),
      meta: {
        styles: {
          minWidth: 200,
          width: '15%',
        },
      },
      cell: ({row}) => <ValueCell value={row.getValue('targetValue')} />,
    },
    {
      accessorKey: 'achievedValue',
      header: ({column}) => (
        <DataTableColumnHeader {...column} title={formatMessage({id: 'NBA_LIST.TABLE_TASK_ACHIVED_VALUE'})} />
      ),
      meta: {
        styles: {
          minWidth: 160,
          width: '15%',
        },
      },
      cell: ({row}) => <ValueCell value={row.getValue('achievedValue')} />,
    },
    {
      accessorKey: 'achievedValue',
      header: ({column}) => (
        <DataTableColumnHeader {...column} title={formatMessage({id: 'REPORTS.TASK_PROGRESS_PER'})} />
      ),
      meta: {
        styles: {
          minWidth: 150,
          width: '15%',
        },
      },
      cell: ({row}) => (
        <PregressPercentageCell total={String(row.original.targetValue)} value={row.getValue('achievedValue')} />
      ),
    },
  ]
}

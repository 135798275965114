import {useCallback, useEffect, useMemo, useState} from 'react'
import {DEFAULT_PAGE_SIZE, DEFAULT_SORT, getStoreReportUseCase, GetReportRequestSchema} from '@UseCases'
import {useSearchParams} from 'react-router-dom'
import {PaginationState, SortingState} from '@tanstack/react-table'
import {store} from '@/store'
import {showNotification} from '@ReduxActions'
import {useQuery, keepPreviousData, useQueryClient} from '@tanstack/react-query'

const initialRequestParams = {
  pageNumber: 0,
  pageSize: DEFAULT_PAGE_SIZE,
  sort: DEFAULT_SORT,
  query: '',
}

export const useReportList = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const pageInSearchParams = searchParams.get('pageNumber')
  const sortInSearchParams = searchParams.get('sort')
  const sizeInSearchParams = searchParams.get('pageSize')
  const queryInSearchParams = searchParams.get('query')
  const nbaId = searchParams.get('nbaId') ?? ''

  const queryClient = useQueryClient()

  const useRequestParams = (
    pageInSearchParams: string | null,
    sizeInSearchParams: string | null,
    sortInSearchParams: string | null,
    queryInSearchParams: string | null
  ) => {
    const [requestParams, setRequestParams] = useState<Required<GetReportRequestSchema>>({
      ...initialRequestParams,
      pageNumber: Math.max(Math.max(0, Number(pageInSearchParams) - 1), initialRequestParams.pageNumber),
      pageSize: Number(sizeInSearchParams) || initialRequestParams.pageSize,
      sort: sortInSearchParams?.length ? sortInSearchParams : initialRequestParams.sort,
      query: queryInSearchParams || initialRequestParams.query,
      nbaId,
    })

    return {requestParams, setRequestParams}
  }

  const useOnPaginationDidChange = (
    setRequestParams: React.Dispatch<React.SetStateAction<Required<GetReportRequestSchema>>>,
    setSearchParams: ReturnType<typeof useSearchParams>[1]
  ) => {
    return useCallback(
      ({pageIndex, pageSize}: PaginationState) => {
        setRequestParams(prev => ({...prev, pageNumber: pageIndex, pageSize: pageSize}))
        setSearchParams(oldParams => {
          oldParams.set('page', `${pageIndex + 1}`)
          return oldParams
        })
      },
      [setRequestParams, setSearchParams]
    )
  }

  const useOnSortingDidChange = (
    requestParams: Required<GetReportRequestSchema>,
    setRequestParams: React.Dispatch<React.SetStateAction<Required<GetReportRequestSchema>>>,
    setSearchParams: ReturnType<typeof useSearchParams>[1]
  ) => {
    return useCallback(
      (sorting: SortingState) => {
        const column = sorting[0]
        const sortIndicator = column?.desc ? 'desc' : 'asc'
        const newSort = !column ? '' : `${column.id},${sortIndicator}`

        setRequestParams({
          ...requestParams,
          sort: newSort,
        })
        setSearchParams(oldParams => {
          oldParams.set('sort', newSort)
          return oldParams
        })
      },
      [requestParams, setRequestParams, setSearchParams]
    )
  }

  const useSortingColumn = (sort: string): SortingState => {
    return useMemo(() => {
      const [id, desc] = sort.split(',')
      return id ? [{id, desc: desc === 'desc'}] : []
    }, [sort])
  }

  const useOnSearchQueryChange = (
    requestParams: Required<GetReportRequestSchema>,
    setRequestParams: React.Dispatch<React.SetStateAction<Required<GetReportRequestSchema>>>,
    setSearchParams: ReturnType<typeof useSearchParams>[1]
  ) => {
    return useCallback(
      (query: string) => {
        setRequestParams({
          ...requestParams,
          query,
        })
        setSearchParams(oldParams => {
          oldParams.set('query', query)
          return oldParams
        })
      },
      [requestParams, setRequestParams, setSearchParams]
    )
  }

  const onLoadReportLoadError = (message: string) => {
    store.dispatch(
      showNotification({
        show: true,
        type: 'error',
        title: 'Something went wrong!',
        message: message ?? 'Please try again later.',
      })
    )
  }

  const {requestParams, setRequestParams} = useRequestParams(
    pageInSearchParams,
    sizeInSearchParams,
    sortInSearchParams,
    queryInSearchParams
  )
  const onPaginationDidChange = useOnPaginationDidChange(setRequestParams, setSearchParams)
  const onSortingDidChange = useOnSortingDidChange(requestParams, setRequestParams, setSearchParams)
  const sortingColumn = useSortingColumn(requestParams.sort)
  const onSearchQueryChange = useOnSearchQueryChange(requestParams, setRequestParams, setSearchParams)

  const {isFetching, data, error} = useQuery({
    queryKey: ['store-reports', requestParams],
    queryFn: ({signal}) => getStoreReportUseCase(requestParams, signal),
    placeholderData: keepPreviousData,
  })

  useEffect(() => {
    if (error) {
      console.error('ReportsListTable::useEffect::error', error)
      onLoadReportLoadError(error.message)
    }
  }, [error])

  const reload = () => {
    if (pageInSearchParams || sizeInSearchParams) {
      onPaginationDidChange({
        pageIndex: pageInSearchParams ? initialRequestParams.pageNumber : requestParams.pageNumber,
        pageSize: sizeInSearchParams ? initialRequestParams.pageSize : requestParams.pageSize,
      })
    }

    if (sortInSearchParams) {
      const [id, desc] = initialRequestParams.sort.split(',')
      onSortingDidChange([{id, desc: desc === 'desc'}])
    }

    if (queryInSearchParams) {
      onSearchQueryChange(queryInSearchParams)
    }

    queryClient.invalidateQueries({queryKey: ['store-reports', requestParams]})
  }

  return {
    isFetching,
    data,
    error,
    requestParams,
    onPaginationDidChange,
    onSortingDidChange,
    sortingColumn,
    onSearchQueryChange,
    reload,
  }
}

import {CountrySelector} from '@/components/NBAEditor/components/CountrySelector'
import {useCountries} from '@/hooks/useCountries'
import {PageHeader} from '@/shared-components/PageHeader'
import {useAppDispatch} from '@/store'
import {NBAStatus} from '@/types/NBAStatus'
import {Search} from 'pepsico-ds'
import {useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageContent} from './Template/components/PageContent'
import {Tab, TabsNavigation} from 'pepsico-ds'
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom'
import {ENDPOINTS, ROUTES} from '@/config/constants'
import {ReportsListTable, ReportsListTableHandler} from '@/components/ReportsListTable'
// import {ReportsFilter} from '@/components/ReportsFilter'
import {setCountry} from '@/store/slices/commonSlice'
import {ReportsTaskListTable} from '@/components/ReportsTaskListTable'
import {SecondaryButton} from '@/shared-components/Button'
import {downloadFileHandler} from '@/helpers/downloadFileHandler'
import {FeatureConfig} from '@/config/featureToggles'

export const StoreAndTaskReports = () => {
  const {formatMessage} = useIntl()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [searchParams] = useSearchParams()
  const nbaId = searchParams.get('nbaId') ?? undefined
  const nbaName = searchParams.get('nbaName') ?? undefined

  const {pathname} = useLocation()

  const {country, countries} = useCountries()

  const tableListTableRef = useRef<ReportsListTableHandler>(null)

  const onCountryDidChange = (countryCode: string) => {
    const newCountry = countries.find(c => c.code === countryCode)
    newCountry && dispatch(setCountry(newCountry))
    tableListTableRef.current?.reload()
  }

  // DO not remove this code
  const onNBANameSearch = (query: string) => {
    tableListTableRef.current?.onSearchQueryChange(query)
  }

  const TabData = [
    {
      heading: formatMessage({id: 'REPORTS.STORE_REPORTS_HEADING'}),
      description: formatMessage({id: 'REPORTS.STORE_REPORTS_DESC'}),
    },
    {
      heading: formatMessage({id: 'REPORTS.TASK_REPORTS_HEADING'}),
      description: formatMessage({id: 'REPORTS.TASK_REPORTS_DESC'}),
    },
  ]

  useEffect(() => {
    setSelectedIndex(pathname.includes(ROUTES.TASK_REPORTS) ? 1 : 0)
  }, [pathname])

  if (!country) {
    return null
  }

  return (
    <PageContent
      data-testid="nbas-list-page"
      pageContentWrapperClassName="relative"
      pageHeaderContent={
        <div className="absolute -top-12 right-0 flex flex-row items-center gap-2 text-xs font-normal">
          <p className="text-nowrap">{formatMessage({id: 'GENERAL.SELECT_MARKET'})}</p>
          <CountrySelector
            id="main-country"
            status={NBAStatus.draft}
            value={country.code}
            onValueChanged={onCountryDidChange}
            className="w-fit"
          />
        </div>
      }
    >
      <div className="mb-2">
        <div className="flex items-center justify-between">
          <PageHeader title={TabData[selectedIndex].heading} className="relative justify-start" />
          <div className="w-[220px]">
            <Search placeholderText="Search by NBA name" className="w-[220px]" onUpdate={onNBANameSearch} />
          </div>
        </div>

        <div className="my-2 flex items-start gap-4 text-base" data-testid="subtitle-content">
          <div className="subtitle flex-1 text-sm text-text-secondary">{TabData[selectedIndex].description}</div>
        </div>

        <div className="mt-2 flex items-start gap-4 text-base" data-testid="subtitle-content">
          <div className=" flex-1 text-sm text-text-secondary">
            {/* <ReportsFilter /> */}
            <TabsNavigation selectedIndex={selectedIndex}>
              <Tab
                onClick={() =>
                  nbaId
                    ? navigate(`${ROUTES.NBA_REPORTS}?nbaId=${nbaId}&nbaName=${nbaName}`)
                    : navigate(ROUTES.NBA_REPORTS)
                }
                label={formatMessage({id: 'REPORTS.STORE_STATUS'})}
              />
              <Tab
                onClick={() =>
                  nbaId
                    ? navigate(`${ROUTES.TASK_REPORTS}?nbaId=${nbaId}&nbaName=${nbaName}`)
                    : navigate(ROUTES.TASK_REPORTS)
                }
                label={formatMessage({id: 'REPORTS.TASK_STORE_STATUS'})}
              />
            </TabsNavigation>
          </div>

          <div className="button-container ml-auto flex gap-4">
            <SecondaryButton
              size="small"
              className="ml-auto"
              data-testid="button-download-report"
              data-cy="button-download-report"
              onClick={() => {
                const fileName: string = `${nbaId}.zip`
                downloadFileHandler(
                  `${import.meta.env[FeatureConfig.apiUrl]}${
                    ENDPOINTS.EXPORT_FILE
                  }?nbaId=${nbaId}&countryCode=${country}fileName=${nbaId}.zip&reportType=${
                    selectedIndex === 0 ? 'store' : 'task'
                  }`,
                  fileName
                )
              }}
            >
              {formatMessage({id: 'REPORTS.EXPORT_REPORT'})}
            </SecondaryButton>
          </div>
        </div>
      </div>

      {selectedIndex === 0 && <ReportsListTable reporttype="store-reports" ref={tableListTableRef} />}
      {selectedIndex === 1 && <ReportsTaskListTable reporttype="task-reports" ref={tableListTableRef} />}
    </PageContent>
  )
}
